// extracted by mini-css-extract-plugin
export var banner = "lamps-module--banner--16Uz3";
export var openingParagraph = "lamps-module--openingParagraph--hX4Y1";
export var row1 = "lamps-module--row1--nJTHt";
export var row2 = "lamps-module--row2--31b9v";
export var row3 = "lamps-module--row3--1wRWi";
export var styles = "lamps-module--styles--ls8g2";
export var imgText = "lamps-module--imgText--qp8Z7";
export var leftImage = "lamps-module--leftImage--3May4";
export var rightImage = "lamps-module--rightImage--3UDUW";
export var card = "lamps-module--card--2k80Q";
export var colorCard = "lamps-module--colorCard--34Qsd";