import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import Seo from "../../components/SEO"

import { Container, Row, Col } from 'react-bootstrap'
import * as lampStyles from '../../styles/lamps.module.scss'


const Lamps = ({data}) => {

  let linkStyle = {
      textDecoration: 'none'
  }

  const row2img = withArtDirection(
    getImage(data.row2), 
    [
        {
            media: "(max-width: 576px)",
            image: getImage(data.row2_mobile)
        },
    ])


    const StylesRow = <Row xs={{cols: 2}} sm={{cols: 3}} className={lampStyles.styles}>
            <Col xs={{order: 1}} sm={{order: 1}}>
              <Link to="/lamps/brown/" style={linkStyle}>
                <div className={lampStyles.card} >
                  <GatsbyImage 
                    image={data.brown.childImageSharp.gatsbyImageData} 
                    alt="Brown" />
                  <p>Larix Medium <br /> Brown</p> 
                </div>
              </Link>
            </Col>
            <Col xs={{order: 3, span: 12}} sm={{order: 2}}>
              <Link to="/lamps/color/" style={linkStyle}>
                <div className={`${lampStyles.card} ${lampStyles.colorCard}`} >
                  <GatsbyImage 
                    image={data.color.childImageSharp.gatsbyImageData} 
                    alt="Color" />
                  <p>Larix Medium <br /> Color</p> 
                </div> 
              </Link>
            </Col>
            <Col xs={{order: 2}} sm={{order: 3}}>
              <Link to="/lamps/gold/" style={linkStyle}>
                <div className={lampStyles.card} >
                  <GatsbyImage 
                    image={data.gold.childImageSharp.gatsbyImageData} 
                    alt="Gold" />
                  <p>Larix Medium <br /> Gold</p> 
                </div>
              </Link>
            </Col>
        </Row>


  return (
    <>
        <Seo title={"Lamps"} />
        { /* Row 0 -- Banner */}
        <div className={lampStyles.banner}>
            <Container>
              <h1> LATUSS LARIX MEDIUM LAMPS </h1>
              { StylesRow }
              <div className={lampStyles.openingParagraph}>
                <span>
                  An artist tells, the lamp speaks, so that the room is eloquent.
                </span>
              </div>
            </Container>
        </div>
        { /* row 1 -- Top */}
        <div className={lampStyles.row1}
            data-sal="slide-up" 
            data-sal-delay={`0`}
        >
            <Container>
              <Row xs={{cols: 1}} sm={{cols: 2}}>
                  <Col>
                      <h2>SHADE</h2>
                      <p className={lampStyles.imgText}>
                        The silken shade is the most noticable and important part of the lamp.
                        The embroidered patterns come to life especially when the lamp is lit
                        and the light gently sifts through the silk.
                      </p>
                  </Col>
                  <Col>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <GatsbyImage
                          image={data.row1.childImageSharp.gatsbyImageData}
                          className={lampStyles.rightImage}
                          alt="Lamp Shade" />
                    </div>
                  </Col>
              </Row>
            </Container>
        </div>
        { /* row 2 -- Body */}
        <div className={lampStyles.row2}
            data-sal="slide-up" 
            data-sal-delay={`0`}
        >
            <Container>
              <Row xs={{cols: 1}} sm={{cols: 2}}>
                  <Col>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <GatsbyImage
                        image={row2img}
                        className={lampStyles.leftImage}
                        alt="Lamp Body" />
                      </div>
                  </Col>
                  <Col>
                      <h2>BODY</h2>
                      <p className={lampStyles.imgText}>
                          The wooden body connects the shade to the base,
                          meaningfully complementing them with hand-painted 
                          and gilded shapes.
                      </p>
                  </Col>
              </Row>
            </Container>
        </div>
        { /* row 3 -- Bottom */}
        <div className={lampStyles.row3}
            data-sal="slide-up" 
            data-sal-delay={`0`}
        >
            <Container>
              <Row xs={{cols: 1}} sm={{cols: 2}}>
                  <Col>
                      <h2>BASE</h2>
                      <p className={lampStyles.imgText}>
                        The wooden base is, despite its supporting role,
                        richly decorated with hand-made paintings and gilding.
                      </p>
                  </Col>
                  <Col>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <GatsbyImage
                        image={data.row3.childImageSharp.gatsbyImageData}
                        className={lampStyles.rightImage}
                        alt="Lamp Base" />
                      </div>
                  </Col>
              </Row>
            </Container>
        </div>
        { /* row 4 -- Styles */}
        <div className={lampStyles.styles}
            data-sal="slide-up" 
            data-sal-delay={`0`}
        >
            <Container>
              <p> Available in 3 styles</p> 
              <hr />
                <Row xs={{cols: 2}} sm={{cols: 3}}>
                  <Col xs={{order: 1}} sm={{order: 1}}>
                    <Link to="/lamps/brown/" style={linkStyle}>
                      <div className={lampStyles.card} >
                        <GatsbyImage 
                          image={data.brown.childImageSharp.gatsbyImageData} 
                          alt="Brown" />
                        <p>Larix Medium <br /> Brown</p> 
                      </div>
                    </Link>
                  </Col>
                  <Col xs={{order: 3, span: 12}} sm={{order: 2}}>
                    <Link to="/lamps/color/" style={linkStyle}>
                      <div className={`${lampStyles.card} ${lampStyles.colorCard}`} >
                        <GatsbyImage 
                          image={data.color.childImageSharp.gatsbyImageData} 
                          alt="Color" />
                        <p>Larix Medium <br /> Color</p> 
                      </div> 
                    </Link>
                  </Col>
                  <Col xs={{order: 2}} sm={{order: 3}}>
                    <Link to="/lamps/gold/" style={linkStyle}>
                      <div className={lampStyles.card} >
                        <GatsbyImage 
                          image={data.gold.childImageSharp.gatsbyImageData} 
                          alt="Gold" />
                        <p>Larix Medium <br /> Gold</p> 
                      </div>
                    </Link>
                  </Col>
              </Row>
            </Container>
        </div>
    </>
  );
}


export default Lamps


export const query = graphql`
query getImages($color: String = "#c9ae9f"){
  row1: file(relativePath: {eq: "lamps/tkanina_circ.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 420
        quality: 75
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: $color, threshold: 90}
      )
    }
  }
  row2: file(relativePath: {eq: "lamps/body_circ_brown.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 420
        quality: 75
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: $color}
      )
    }
  }
  row2_mobile: file(relativePath: {eq: "lamps/body_circ_white.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 420
        quality: 75
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: $color}
      )
    }
  }
  row3: file(relativePath: {eq: "lamps/podstavek_circ.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 420
        quality: 75
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: $color, threshold: 165}
      )
    }
  }
  lamp: file(relativePath: {eq: "color/color01.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 375
        quality: 75
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: $color, threshold: 180}
      )
    }
  }
  brown: file(relativePath: {eq: "brown/brown01.png"}) {
    childImageSharp {
      gatsbyImageData(
        height: 375
        quality: 90
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: $color, threshold: 135}
      )
    }
  }
  color: file(relativePath: {eq: "color/color01.png"}) {
    childImageSharp {
      gatsbyImageData(
        height: 375
        quality: 90
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: $color, threshold: 180}
      )
    }
  }
  gold: file(relativePath: {eq: "gold/gold01.png"}) {
    childImageSharp {
      gatsbyImageData(
        height: 375
        quality: 90
        placeholder: TRACED_SVG
        layout: CONSTRAINED
        tracedSVGOptions: {color: $color, threshold: 127}
      )
    }
  }
}
`
